import { render, staticRenderFns } from "./CookiesPolicies.vue?vue&type=template&id=3735f626&scoped=true&"
import script from "./CookiesPolicies.vue?vue&type=script&lang=ts&"
export * from "./CookiesPolicies.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3735f626",
  null
  
)

export default component.exports